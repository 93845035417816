/*

-------- UAT Link ---------------------
API --> https://oasapi.iddriver.com
Front --> https://oas.iddriver.com
Back --> https://oasadmin.iddriver.com
Web Info --> http://information.iddriver.com

-------- Production Link --------------
API --> https://dot-api.mpwt.gov.la
Front --> https://dot-learning.mpwt.gov.la
Back --> https://dot-admin.mpwt.gov.la
Web Info --> https://dot-smart.mpwt.gov.la

*/
export const API_BASE_URL = 'https://dot-api.mpwt.gov.la';
export const API_HEADERS = {
  'Content-Type': 'application/json',
  'Authorization': 'ZeBuphebrltl3uthIFraspubroST80Atr9tHuw5bODowi26p'
};
export const LINK_LEARNING = 'https://dot-learning.mpwt.gov.la';