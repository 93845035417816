import React from 'react';
import { useTranslation } from 'react-i18next';


const CourseDetailC1 = () => {
    const {t} = useTranslation();
    let publicUrl = process.env.PUBLIC_URL + '/';
    let course_code="C1";

    return <div>
        <div className="pd-top-60">
            <div className="container">
                <div className="row pd-bottom-70" >
                    <div className="col-12 col-md-3">
                    <img className="" src={publicUrl+'assets/img/course/'+course_code+'.jpg'} style={{objectFit: 'cover'}} width={180}  alt={"img-" + course_code} />
                    </div>
                    <div className="col-12 col-md-9 py-2">
                        <h3 className="fw-bold fs-4">C1 - ລົດຂົນສົ່ງສິນຄ້ານ້ຳໜັກລວມ ເກີນກວ່າ 7500ກິໂລກຣາມ ຫາ 15000 ກິໂລກຣາມ
                            <br/> Cargo truck total weight exceed 7500 kilogram up to 15000 kilograms
                        </h3>
                        <br/>
                        <div className='fs-5 fw-bold'>ໄລຍະເວລາ ການຝຶກອົບຮົມຫຼັກສູດ 30 ຊົ່ວໂມງ</div>
                            <p>
                                -ພາກທິດສະດີ 10 ຊົ່ວໂມງ
                                <br/>-ພາກປະຕິບັດ 20 ຊົ່ວໂມງ.
                            </p>
                            <p>
                                * ບັນຍາຍໃນຫ້ອງສຳລັບທິດສະດີ
                                <br/>* ຝຶກຊ້ອມໃນສະໜາມຝຶກຊ້ອມ ແລະ ຝຶກຂັບຂີ່ໃນຖະໜົນຈິງ.
                            </p>
                    </div>
                </div>
                <div className="row justify-content-center" >
                    <div className='fs-5 col-12 col-md-3 fw-bold'>ວິທີການປະເມີນຜົນ :</div>
                    <div className="col-12 col-md-9 py-2">
                        -ການທົດສອບຄວາມຮູ້ຫຼັງຝຶກອົບຮົມ
                        <br/>-ການທົດສອບທັກສະປະຕິບັດ.
                        <br/>-ກວດເວລາໃນການເຂົ້າຮ່ວມຝຶກອົບຮົມ.
                        
                    </div>
                </div>
                <div className="row justify-content-center" >
                    <div className='fs-5 col-12 col-md-3 fw-bold'>ເກນການປະເມີນຜົນ :</div>
                    <div className="col-12 col-md-9 py-2">
                        -ມີຄະແນນສອບເສັງຄວາມຮູ້ພາຍຫຼັງການຝຶກອົບຮົມ. ບໍ່ຕ່ຳກວ່າ 90 ເປີເຊັນ.
                            <br/>-ຜ່ານການສອບເສັງພາກປະຕິບັດ ບໍ່ຕ່ຳກວ່າ 3 ທ່າບັງຄັບ
                    </div>
                </div>
                <div className="row justify-content-center" >
                    <div className='fs-5 col-12 col-md-3 fw-bold'>ຄຸນສົມບັດຂອງນັກຮຽນ :</div>
                    <div className="col-12 col-md-9 py-2">
                    - ອາຍຸ 25 ປີຂື້ນໄປ
                    <br/> -ມີໃບຂັບຂີ່ປະເພດ C ມາກ່ອນຢ່າງໜ້ອຍ 2 ປີ
    <br/> -ສາມາດອ່ານ ແລະ ຂຽນໄດ້
    <br/> -ມີຄວາມຮູ້ຕາມຂໍ້ບັງຄັບຂອງກົດໝາຍກ່ຽວກັບການຂົນສົ່ງ ແລະ ກົດຈະລາຈອນ
    <br/> -ບໍ່ເປັນຄົນພິການທາງຮ່າງກາຍ ຂັ້ນບໍ່ສາມາດຂີ່ລົດໄດ້.
    <br/> -ບໍ່ມີພະຍາດປະຈຳຕົວທີ່ແພດພິຈາລະນາວ່າອາດເປັນອັນຕະລາຍໃນເວລາຂັບຂີ່.
    <br/> -ບໍ່ເປັນຄົນເຈັບປ່ວຍທາງຈິດ
    <br/> -ບໍ່ເປັນຜູ້ທີ່ຢູ່ລະຫວ່າງ ຖືກຍຶດຫຼືຖືກໂຈະ ໃບຂັບຂີ່.
    <br/> -ບໍ່ເຄີຍຖືກຖອນໃບຂັບຂີ່ ພາຍໃນ3 ປີຜ່ານມາ, ຍ້ອນເຫດຜົນອື່ນໆ ທີ່ບໍ່ແມ່ນຂາດຄຸນສົມບັດກ່ຽວກັບອາຍຸ. ທັງນີ້ຜູ້ອໍານວຍການຈະຕ້ອງກໍານົດເງື່ອນໄຂໃນການພິຈາລະນາອອກໃບຂັບຂີ່.

                    </div>
                </div>
                <div className="row justify-content-center" >
                    <div className='fs-5 col-12 col-md-3 fw-bold'>ຈຸດປະສົງຂອງຫຼັກສູດ :</div>
                    <div className="col-12 col-md-9 py-2">
                    - ມີທັດສະນະຄະຕິ ແລະ ຈິດສໍານຶກ ທີ່ດີໃນການຂັບລົດຂົນສົ່ງສິນຄ້າໃຫ້ປອດໄພ
    <br/> -ມີຄວາມຮູ້ໃນການກະກຽມສະພາບຮ່າງກາຍ ແລະ ຈິດໃຈຂອງຜູ້ຂັບຂີ່ລົດບັນທຸກ.
    <br/> -ມີຄວາມຮູ້ ແລະ ຄວາມສາມາດໃນການກະກຽມລົດບັນທຸກເພື່ອຂົນສົ່ງສິນຄ້າ.
   <br/> -ມີຄວາມຮູ້ ແລະ ຄວາມສາມາດ ໃນການຂັບຂີ່ລົດບັນທຸກສິນຄ້າຢ່າງປອດໄພ
   <br/> -ມີຄວາມຊໍານານໃນການປະເມີນ, ຄວບຄຸມ, ແລະແກ້ໄຂສະຖານະການສຸກເສີນ.
   <br/> -ສາມາດບໍລິຫານຈັດການຄວາມເມື່ອຍລ້າ
   <br/> -ມີຄວາມສາມາດໃນການປະເມີນຄວາມສ່ຽງທີ່ສົ່ງຜົນ ຕໍ່ການເກີດອຸປະຕິເຫດ.
   <br/> -ມີຄວາມຮູ້ ແລະ ຄວາມເຂົ້າໃຈກ່ຽວກັບການບໍລິການຂົນສົ່ງສາທາລະນະ.
   <br/> -ເພື່ອໃຫ້ມີຄວາມຮູ້ກ່ຽວກັບກົດລະບຽບຈະລາຈອນ ແລະກົດໝາຍທີ່ກ່ຽວຂ້ອງ
  <br/> -ເພື່ອໃຫ້ມີລະບຽບວິໄນ ແລະ ມາລະຍາດໃນການຂັບລົດ.
  <br/> -ເພື່ອໃຫ້ມີຄວາມຮູ້ໃນການບັນທຸກ ແລະ ມັດຕຶງສິນຄ້າ
   <br/> -ເພື່ອໃຫ້ມີຄວາມຮູ້ ດ້ານການປະຖົມພະຍາບານ ແລະ ຊ່ວຍເຫຼືອເບື້ອງຕົ້ນ.


                    </div>
                </div>

                <table className='table table-bordered'>
                    <thead className='thead-light'>
                    <tr>
                        <th rowSpan={2}>ຫົວຂໍ້ວິຊາ</th>
                        <th colSpan={2} style={{"text-align":"center"}}>ຊົ່ວໂມງ</th>
                    </tr>
                    <tr>
                        <th width={30}>ທິດສະດີ</th>
                        <th width={30}>ປະຕິບັດ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1. ຄວາມຮູ້ກ່ຽວກັບກົດໝາຍຈະລາຈອນ ກົດໝາຍວ່າດ້ວຍລົດ ແລະ ກົດໝາຍທີ່ກ່ຽວຂ້ອງ
       

                        </td>
                        <td>2</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>2. ການກະກຽມທາງດ້ານຮ່າງກາຍແລະຈິດໃຈ, ການຈັດການຄວາມເຫນື່ອຍລ້າ
                           
                        </td>
                        <td>1</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>3. ຄວາມຮູ້ກ່ຽວກັບຫຼັກການຂັບຂີ່ປອດໄພ ແລະ ການຄາດຄະເນສະຖານະການຕ່າງໆ

                        </td>
                        <td>3</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>4. ຄວາມຮູ້ກ່ຽວກັບການບໍາລຸງຮັກສາ ກວດເຊັກລົດກ່ອນໃຊ້ງານ ແລະ ແກ້ໄຂບັນຫາເບື້ອງຕົ້ນ


                        </td>
                        <td>1</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>5. ຄວາມຮູ້ກ່ຽວກັບການບັນທຸກສິນຄ້າ
                           
                        </td>
                        <td>1</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>6. ຄວາມຮູ້ເບື້ອງຕົ້ນກ່ຽວກັບວັດຖຸອັນຕະລາຍ
                       
                        </td>
                        <td>1</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>7. ຄວາມຮູ້ເບື້ອງຕົ້ນກ່ຽວກັບລົດ ແລະ ພື້ນຖານການຂັບລົດ  
                            <ul>
                            - ການກວດເຊັກລົດກ່ອນໃຊ້ງານ ແລະ ອຸປະກອນປະຈໍາສໍາລັບລົດຂົນສົ່ງ
    <br/> -ແນະນໍາອຸປະກອນທີ່ຕ້ອງຄວບຄຸມ ແລະ ວິທີການໃຊ້ອຸປະກອນພາຍໃນລົດ
    <br/> -ການກຽມຄວາມພ້ອມໃນຫ້ອງໂດຍສານກ່ອນອອກລົດ, ປັບບ່ອນນັ່ງ, ປັບກະຈົກ, ປັບພວງມາໄລ
    <br/> -ການຄວບຄຸມລົດໃຫ້ເຄື່ອນທີ່ ແລະການຢຸດລົດ
    <br/> -ການນໍາໃຊ້ clutch, ເກຍ, ຄັນເລັ່ງ ແລະ ເບກ ຢ່າງຖືກຕ້ອງ
    <br/> -ການປ່ຽນແປງໃນລະດັບຄວາມໄວທີ່ແຕກຕ່າງ 
    <br/> -ການຂັບລົດໄປໜ້າ ແລະຖອຍຫຼັງ ໃນທາງຊື່
    <br/> -ການຢຸດລົດ

                            </ul>
                            </td>
                        <td>-</td>
                        <td>4</td>
                    </tr>
                    <tr>
                        <td>8. ການຝຶກຫັດຂັບລົດຕາມທ່າຝຶກຕ່າງໆ
                            <ul>
                            - ຂັບລົດໄປທາງໜ້າ ແລະ ຈອດແຄມທາງຍ່າງ
    <br/> -ການຄວບຄຸມຄວາມໄວຂອງລົດ ການໝຸນພວງມະໄລ, ການກະໄລຍະ
    <br/> -ການລ້ຽວກັບລົດທາງແຄບ
    <br/> -ການຂັບລົດເດີນໜ້າ ແລະ ຖອຍຫຼັງ ຊ່ອງທາງຊື່
    <br/> -ການຂັບລົດຖອຍຫຼັງເຂົ້າຈອດ ແລະ ເດີນໜ້າອອກຈາກຊ່ອງວ່າງດ້ານຊ້າຍຂະໜານກັບຂອບທາງ
    <br/> -ການຂັບລົດລ້ຽວມຸມສາກຕ່າງໆ
    <br/> -ການຂັບລົດຖອຍຫຼັງເຂົ້າຈອດ ແລະ ເດີນໜ້າອອກຈາກຊ່ອງວ່າງດ້ານຊ້າຍມູມສາກ 90 ອົງສາ
    <br/> -ການຂັບລົດຖອຍຫຼັງເຂົ້າຈອດ ແລະ ເດີນໜ້າອອກຈາກຊ່ອງວ່າງດ້ານຂວາມູມສາກ 90 ອົງສາ
    <br/> -ການຂັບລົດຖອຍຫຼັງເຂົ້າຈອດ ແລະ ຖອຍຫຼັງອອກຈາກຊ່ອງວ່າງດ້ານຊ້າຍມູມສາກ 90 ອົງສາ
    <br/> -ການຂັບລົດຖອຍຫຼັງເຂົ້າຈອດ ແລະ ຖອຍຫຼັງອອກຈາກຊ່ອງວ່າງດ້ານຂວາມູມສາກ 90 ອົງສາ    
    <br/> -ການຢຸດລົດ, ການຈອດລົດ, ແລະ ອອກລົດເທິງທາງຄ້ອຍ ຫຼື ຂົວ
    <br/> -ການຢຸດລົດຢູ່ທາງລຽບ
    <br/> -ການຂັບລົດໂດຍປະຕິບັດຕາມເຄື່ອງໝາຍຈະລາຈອນ
    <br/> -ການຂັບລົດ ເຂົ້າ-ອອກ ວົງວຽນ

                            </ul>
                        </td>
                        <td>-</td>
                        <td>13</td>
                    </tr>
                    <tr>
                        <td>9. ການມັດຕຶງສິນຄ້າ ການຄວບຄຸມຜ້າໃບ</td>
                        <td>-</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>10. ການຝຶກຫັດຂັບລົດນອກສະຖານທີ ຕາມສະພາບເສັ້ນທາງຕົວຈິງ</td>
                        <td>-</td>
                        <td>2</td>
                    </tr>

                    </tbody>
                </table>

                <hr className='mt-5 border border-dark' />
            </div>
        </div>

    </div>
}

export default CourseDetailC1

